<template>
	<div>
		<!-- <v-sheet class="recurring-main-listing"> -->
		<v-layout class="mb-1">
			<v-row class="d-flex align-center">
				<v-select
					v-model="filter.month"
					class="listing-select pr-0"
					hide-details
					:disabled="pageLoading"
					:items="monthList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 200px !important; min-width: 200px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getkhExcel"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content class="p-0">
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-select
					v-model="filter.year"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="yearList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 110px !important; min-width: 110px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getkhExcel"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="filter.search"
					autocomplete="off"
					hide-details
					placeholder="search."
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getKeywordHistory()"
					@input="searchKeyword"
					style="max-width: 280px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					@click="getKeywordHistory()"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 mx-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- <v-select
					v-model="statusFilter"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="statusList"
					solo
					item-text="title"
					style="max-width: 128px !important; min-width: 128px !important"
					return-object
					:menu-props="{ offsetY: true, bottom: true }"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select> -->
			</v-row>
			<!-- <v-spacer></v-spacer> -->
			<v-flex class="py-0 my-auto d-flex justify-end listing-right">
				<template>
					<!-- <v-tooltip top>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="blue darken-2"
								class="text-white"
								v-on="on"
								v-bind="attrs"
								link
								href="/media/sample-files/Sample_keyword_history.xlsx"
								download
							>
								@click="downloadSample"
								<v-icon left> mdi-download </v-icon>
								Sample CSV
							</v-btn>
						</template>
						<span>Download Sample CSV File</span>
					</v-tooltip> -->

					<v-tooltip top>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="green darken-4"
								class="text-white"
								v-on="on"
								v-bind="attrs"
								@click="exportCSV()"
							>
								<v-icon left> mdi-table-arrow-down </v-icon>
								CSV
							</v-btn>
						</template>
						<span>Download CSV File</span>
					</v-tooltip>
				</template>
			</v-flex>
		</v-layout>

		<div style="max-height: calc(100vh - 297px); overflow-y: auto; overflow-x: hidden">
			<div class="seo-tabs">
				<v-simple-table width="100%" class="detail-table normal-table table-head-sticky mb-5">
					<thead>
						<tr>
							<th width="5%" class="p-2 light-blue-bg custome-table">SR.No.</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Analysis Code</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Date</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Action</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="pageLoading">
							<tr>
								<td colspan="7" width="100%" class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</td>
							</tr>
						</template>
						<template v-if="khExcel.length">
							<tr
								v-for="(row, index) in khExcel"
								:class="['cursor-pointer', { 'grey lighten-3': row.id === activeRowId }]"
								@click="rowActive(row.id)"
								:key="row.name + '_' + index"
							>
								<td width="3%" class="p-2 border-top-light-grey">
									<!-- <ShowValue :object="row" object-key="id" label="sr"></ShowValue> -->
									{{ index + 1 }}
								</td>
								<td width="20%" class="p-2 border-top-light-grey">
									<!-- <ShowValue :object="row" object-key="name" label="name" class="text-capitalize"></ShowValue> -->
									<Chip :text="row.barcode" color="cyan" v-if="row.barcode"></Chip>
								</td>
								<td width="20%" class="p-2 border-top-light-grey">
									<span>{{ formatDateTime(row.added_at) }}</span>
								</td>
								<td width="20%" class="p-2 border-top-light-grey">
									<v-tooltip top>
										<template #activator="{ on, attrs }">
											<v-btn
												v-on:click.stop="doAction(row, 'download')"
												icon
												depressed
												color="blue darken-4"
												class="mr-2"
												v-on="on"
												v-bind="attrs"
												:ripple="false"
											>
												<v-icon size="25">mdi-download</v-icon>
											</v-btn>
										</template>
										<span>Download {{ row.name }}</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="5">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no file at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</v-simple-table>
			</div>

			<div class="table-detail">
				<v-simple-table width="100%" class="detail-table normal-table table-head-sticky">
					<thead>
						<tr>
							<th width="6.5%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Keyword</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Status</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Date</th>
							<th width="15%" class="p-2 light-blue-bg custome-table">Rank</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="pageLoading">
							<tr>
								<td colspan="7" width="100%" class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</td>
							</tr>
						</template>

						<template v-else-if="activeRowId != null && kwHistory && kwHistory.length > 0">
							<tr v-for="(row, index) in kwHistory" :key="index">
								<td width="3%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="id" label="sr"></ShowValue> -->
									{{ index + 1 }}
									<!-- {{ per_page * (currentPage - 1) + index + 1 }} -->
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
								</td>
								<!-- <td width="20%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="status" label="status"></ShowValue>
										</td> -->
								<td width="6%" class="p-2 border-top-light-grey custome-table-td">
									<v-chip
										class="text-capitalize d-flex justify-center"
										:color="row.status.toLocaleLowerCase() == 'added' ? 'green' : 'red'"
										text-color="#fff"
										:style="{ width: row.status.toLocaleLowerCase() == 'added' ? '70px' : '90px' }"
									>
										{{ row.status.toLocaleLowerCase() == "added" ? "Added" : "Removed" }}
									</v-chip>
								</td>
								<td width="15%" class="p-2 border-top-light-grey custome-table-td">
									{{ formatDateTime(row.added_at) }}
									<!-- <ShowValue :object="row" object-key="added_at" label="added_at"></ShowValue> -->
								</td>
								<td width="15%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="rank" label="rank"></ShowValue>
									<!-- <ShowValue :object="row" object-key="added_at" label="added_at"></ShowValue> -->
								</td>
								<!-- <td width="20%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="current_rank" label="current_rank"></ShowValue>
										</td> -->
								<!-- <td width="22%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="h1" label="h1"></ShowValue>
										</td> -->
								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_20" label="top_20"></ShowValue>
										</td> -->

								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_10" label="top_10"></ShowValue>
										</td>
										<td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_20" label="top_5"></ShowValue>
										</td>
										<td width="10%" class="p-2 border-top-light-grey custome-table-td">
											<ShowValue :object="row" object-key="top_10" label="top_3"></ShowValue>
										</td> -->
							</tr>
						</template>

						<template v-else>
							<tr>
								<td :colspan="7">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no file at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</v-simple-table>
			</div>
			<!-- <Table
					type="seo_analysis"
					delete-endpoint="seo_analysis/"
					v-on:reload:content="filterRows"
					v-on:update:dialog="getInvoicedata"
					delete-note="All transactions of this member will also be deleted."
				></Table> -->

			<ImportDialogSEOTabs
				v-if="importDialog"
				endpoint="seo-keyword-history/import"
				type="keyword_history"
				title="Keyword History"
				:project-data-id="projectId"
				:seo-data-id="seoId"
				:import-dialog.sync="importDialog"
				v-on:close="importDialog = false"
				v-on:refress="getkhExcel"
				sample-file="/media/sample-files/Sample_keyword_history.xlsx"
			></ImportDialogSEOTabs>
			<DeleteTemplate
				v-if="deleteDialog"
				type="Keyword Analyses"
				delete-text="Keyword"
				v-on:success="getkhExcel"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="`seo/project/keyword-analysis/${id}?type=keyword_history`"
			>
			</DeleteTemplate>
			<!-- <v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showing_string }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="total_page"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row> -->
		</div>
		<!-- </v-sheet> -->
	</div>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}

.listing-select .v-input__slot {
	padding-right: 0 !important;
}
:is(.normal-table) thead tr th,
tbody tr td {
	font-size: 13px !important;
	color: rgba(0, 0, 0, 0.87) !important;
}

.normal-table thead tr th {
	font-weight: 500 !important;
}
.normal-table tbody tr td {
	font-weight: 400 !important;
}
.normal-table tbody tr:nth-child(odd) {
	background-color: #e7f4ff;
}
</style>
<script>
/* import Dialog from "@/view/components/Dialog"; */
//import ListingMixin from "@/core/mixins/listing.mixin";
/* import { QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module"; */
import ImportDialogSEOTabs from "@/view/components/ImportDialogSEOTabs.vue";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import Chip from "@/view/components/Chip";
import JwtService from "@/core/services/jwt.service";
// import Chip from "@/view/components/Chip";
// import SampleFile from "./url-mapping.xlsx"
import { mapGetters } from "vuex";

// import {
// 	SET_CURRENT_PAGE,
// 	SET_SHOWING_STRING,
// 	SET_TOTAL_PAGE,
// } from "@/core/services/store/listing.module";

export default {
	name: "Seo-Analysis-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			// monthFilter: { index: 0, title: "All", value: "all" },
			// yearFilter: { year: 2023, index: 0, value: 2023 },
			// statusFilter: { title: "All", value: "all" },
			// month_menu: false,
			// year_menu: false,
			yearList: [],

			filter: {
				month: "",
				year: new Date().getFullYear().toString(),
				search: null,
			},

			monthList: [
				{ index: 0, title: "All Months", value: "" },
				{ index: 1, title: "January", value: "01" },
				{ index: 2, title: "February", value: "02" },
				{ index: 3, title: "March", value: "03" },
				{ index: 4, title: "April", value: "04" },
				{ index: 5, title: "May", value: "05" },
				{ index: 6, title: "June", value: "06" },
				{ index: 7, title: "July", value: "07" },
				{ index: 8, title: "August", value: "08" },
				{ index: 9, title: "September", value: "09" },
				{ index: 10, title: "October", value: "10" },
				{ index: 11, title: "November", value: "11" },
				{ index: 12, title: "December", value: "12" },
			],

			statusList: [
				{ title: "All", value: "all" },
				{ title: "Added", value: "added" },
				{ title: "Removed", value: "removed" },
			],

			pageTitle: "Seo-Analysis",
			pageBreadcrumbs: [{ text: "Seo-Analysis", disabled: true }],
			activeRowId: 1,
			endpoint: "seo-analysis",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			importDialog: false,
			kwHistory: [],
			khExcel: [],
			DataSearch: null,
			page: 1,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			per_page: 5,
			total_page: null,
			deleteDialog: false,
			seoId: null,
			id: null,
			timer: null,
		};
	},
	props: {
		projectId: {
			type: Number,
			default: 0,
		},
		fileName: {
			type: String,
			default: "",
		},
	},
	methods: {
		searchKeyword() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getKeywordHistory();
			}, 600);
		},
		rowActive(id) {
			if (id != this.activeRowId) {
				this.activeRowId = id;
				this.getKeywordHistory();
			}
		},
		statusColor(value) {
			return +value >= 200 && +value < 300
				? "green"
				: +value >= 300 && +value < 400
				? "orange"
				: "red";
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					this.exportCSV(row.url);
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		downloadSample() {
			var anchor = document.createElement("a");
			anchor.setAttribute("href", "./url-mapping.xlsx");
			anchor.setAttribute("download", "Sample_keyword_history.xlsx");
			document.body.appendChild(anchor);
			anchor.click();
			anchor.parentNode.removeChild(anchor);
		},
		exportCSV(url = null) {
			ApiService.setHeader();
			let downloadURL = url
				? url
				: this.activeRowId
				? process.env.VUE_APP_API_URL +
				  `seo/${this.seoId}/project/${this.projectId}/keyword-history-export?keyword_history_id=${this.activeRowId}`
				: "";

			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);
			const project_name = this.fileName;

			const customName =
				project_name +
				" - keyword history (" +
				this.formatDateTimeRawWithoutTime(new Date()) +
				").xlsx";

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		getKeywordHistory(activeId = this.activeRowId.toString()) {
			this.pageLoading = true;
			const payload = {
				keyword_history_id: activeId,
				sort: "asc",
				status: null,
				// current_page: this.currentPage.toString(),
				per_page: 10000,
				search: this.filter.search || null,
			};
			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/seo-keyword-history`, payload)
				.then(({ data }) => {
					this.kwHistory = data;
					// this.showing_string = data.showing_String;
					// this.total_page = data.totalPages;
					// this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					// this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getkhExcel() {
			this.pageLoading = true;
			ApiService.query(
				`seo/${this.seoId}/project/${this.projectId}/excel-seo-keyword-history`,
				this.filter
			)
				.then(({ data, status }) => {
					this.khExcel = data || [];
					if (status && data.length > 0) {
						let id = data && data.length > 0 ? data[0].id : null;
						this.activeRowId = id;
						this.getKeywordHistory(id);
					} else {
						this.activeRowId = null;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		// updatePagination(param) {
		// 	if (this.currentPage == param) {
		// 		return false;
		// 	}

		// 	this.$store.commit(SET_CURRENT_PAGE, param);
		// 	this.$nextTick(() => {
		// 		this.getKeywordHistory();
		// 	});
		// },
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.filter.search = null;
				this.getKeywordHistory();
			});
		},
		getYears() {
			let current_year = +new Date().toISOString().slice(0, 4);
			let _yearList = [];
			while (_yearList.length < 10) {
				let obj = {
					title: current_year.toString(),
					index: _yearList.length,
					value: current_year.toString(),
				};
				_yearList.push(obj);
				current_year = current_year - 1;
			}
			this.yearList = [..._yearList];
		},
	},
	components: {
		ImportDialogSEOTabs,
		ShowValue,
		Chip,
		DeleteTemplate,
		// Chip,
	},
	mounted() {
		if (this.$route.params.id) {
			this.seoId = this.$route.params.id;
			this.getkhExcel();
		}
		// this.getKeywordHistory();
		// this.getkhExcel();
		this.getYears();
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
		// mod_kwHistory() {
		// 	// if (this.statusFilter.title !== "All") {
		// 	// 	return this.kwHistory.filter((v) => v.status === this.statusFilter.title);
		// 	// }
		// 	return this.kwHistory;
		// },
	},
};
</script>
